import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { USER_DATA_QUERY } from '../utils/auth-queries';

export const useBusinesses = () => {
  const { result, loading } = useQuery(USER_DATA_QUERY);
  const user = useResult(result);

  const businesses = computed(() => {
    if (!user.value) return [];
    return user.value.memberships.map(({ business }) => business);
  });

  return {
    businesses,
    loading,
  };
};
