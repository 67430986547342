import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useAuthToken } from './authToken';

import { ROLE_TYPE } from '@/modules/users/constants';

export const useCurrentUserRole = () => {
  const { error } = useNotification();
  const { currentTenantId } = useTenancy();
  const { tokenPayload } = useAuthToken();

  const variables = computed(() => ({ id: tokenPayload.value?.sub }));

  const { result, loading, onError, refetch } = useQuery(NEW_USER_QUERY, variables, () => ({
    fetchPolicy: 'cache-first',
    enabled: !!variables.value.id,
  }));

  const user = computed(() => result.value?.user);

  const role = computed(() => {
    const currentRole = user.value?.memberships.find(({ businessId }) => currentTenantId.value === businessId)?.role;
    if (!currentRole) {
      if (user.value?.memberships.some(({ role }) => role === ROLE_TYPE.ADMIN)) {
        return ROLE_TYPE.ADMIN;
      }
    }
    return currentRole;
  });

  onError((err) => {
    console.error('auth - useUser', err);
    error();
  });

  return {
    role,
    loading,
    refetch,
  };
};

export const NEW_USER_QUERY = gql`
  query userNew($id: ID!) {
    user(id: $id) {
      memberships {
        role
        businessId
      }
    }
  }
`;
