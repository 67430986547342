<template>
  <ReconciliationTaskTable :title="$t('operationManagement.tabs.balanceBillings')" />
</template>

<script>
import { ReconciliationTaskTable } from '../components/operationManagement';

export default {
  components: {
    ReconciliationTaskTable,
  },
};
</script>
