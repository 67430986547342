import { gql } from '@apollo/client/core';
import { watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useAuthToken } from './authToken';
import { useNotification } from '@/modules/core';
import i18n, { getDefaultSystemLocale } from '@/imports/startup/client/i18n';

function decodeBase64UrlFromToken(token) {
  const jwtPayloadBase64 = token.split('.')[1];
  return jwtPayloadBase64.replace(/-/g, '+').replace(/_/g, '/');
}

function decodeBase64ToBinary(base64Url) {
  return decodeURIComponent(
    window
      .atob(base64Url)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export const useUser = () => {
  const { error } = useNotification();
  const { token, isUserAnAdmin } = useAuthToken();

  const { result, loading, onError, refetch } = useQuery(
    USER_QUERY,
    () => ({}),
    () => ({ fetchPolicy: 'cache-first' })
  );
  const user = useResult(result);

  watch(
    user,
    () => {
      if (user.value?.profile.locale) i18n.locale = user.value.profile.locale;
      else if (token.value) {
        const decodedBase64 = decodeBase64UrlFromToken(token.value);
        const jwtPayloadJson = JSON.parse(decodeBase64ToBinary(decodedBase64));

        if (!jwtPayloadJson.locale) i18n.locale = getDefaultSystemLocale();
        else i18n.locale = jwtPayloadJson.locale;
      } else i18n.locale = getDefaultSystemLocale();
    },
    { immediate: true }
  );

  onError((err) => {
    console.error('auth - useUser', err);
    error();
  });

  return {
    token,
    user,
    isAdmin: isUserAnAdmin,
    loading,
    refetch,
  };
};

export const USER_QUERY = gql`
  query user {
    me {
      id
      username
      profile {
        firstName
        lastName
        profilePicture
        cellphone
        locale
      }
    }
  }
`;
