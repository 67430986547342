import { ref, computed, getCurrentInstance, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useAuthToken } from './authToken';
import { USER_DATA_QUERY } from '../utils/auth-queries';
import { apolloClient } from '@/plugins/apollo-client';

let currentTokenId = '';
const invalidatePermissionsCache = () => {
  apolloClient.cache.evict({
    fieldName: 'userPermissionGet',
  });
  apolloClient.cache.gc();
};

export const useTenancy = () => {
  const { token, tokenPayload } = useAuthToken();
  const instance = getCurrentInstance().proxy;
  const tenantId = computed(() => instance.$route.params.tenantId);

  const { result, loading: userDataLoading } = useQuery(
    USER_DATA_QUERY,
    () => ({}),
    () => ({ fetchPolicy: 'cache-first' })
  );

  watch(
    tokenPayload,
    () => {
      if (tokenPayload.value && tokenPayload.value.tokenId && tokenPayload.value.tokenId !== currentTokenId) {
        currentTokenId = tokenPayload.value.tokenId;
        invalidatePermissionsCache();
      }
    },
    { immediate: true, deep: true }
  );

  const user = computed(() => result?.value?.me || { memberships: [] });
  const tenancyLoading = ref(true);
  const currentTenant = computed(() => {
    const fallbackCurrentTenant = { id: tenantId.value };
    if (userDataLoading.value) {
      return fallbackCurrentTenant;
    }
    if (!tenantId.value) {
      return undefined;
    }
    tenancyLoading.value = false;
    const activeTenantMembership = user.value.memberships.find((membership) => {
      return membership.business.id === tenantId.value;
    });
    const activeTenantBusiness = activeTenantMembership?.business;
    return activeTenantBusiness ?? fallbackCurrentTenant;
  });

  return {
    token: computed(() => token.value),
    currentTenant,
    currentTenantId: tenantId,
    loading: tenancyLoading,
  };
};
