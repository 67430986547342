import Vue, { computed } from 'vue';

export const state = Vue.observable({
  graph: {
    activeItem: null,
    activeTree: [],
  },
  selectedItem: null,
});

export function setGraphActiveItem(id) {
  state.graph.activeItem = id;
}

export function setGraphActiveTree(tree) {
  state.graph.activeTree = tree || [];
}

export function setSelectedItem(item) {
  state.selectedItem = item;
}

export function useSelectedItem() {
  return computed({
    get: () => state.selectedItem,
    set: (value) => {
      state.selectedItem = value;
    },
  });
}
