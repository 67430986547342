import { isNil } from 'ramda';
import { watch, reactive, toRefs } from 'vue';

// TODO - improve state management, add setters functions instead toRefs
const state = reactive({ selectedCredit: null, showCreditMatchModal: false });

watch(
  () => state.selectedCredit,
  () => {
    state.showCreditMatchModal = !isNil(state.selectedCredit);
  },
  { immediate: true, deep: true }
);

watch(
  () => state.showCreditMatchModal,
  () => {
    if (!state.showCreditMatchModal) {
      state.selectedCredit = null;
    }
  },
  { immediate: true }
);

export const useActivityState = () => {
  return { ...toRefs(state) };
};
