<template>
  <OngoingTable
    :closed-reconciliations="false"
    :supplier-id="supplierId"
    :title="$t('operationManagement.tabs.open')"
    :sort="getSort('paymentDueDate', 'asc')"
  />
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { OngoingTable } from '../components/operationManagement';

export default {
  components: {
    OngoingTable,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const supplierId = computed(() => {
      const { supplierId } = root.$route.query;
      return supplierId || null;
    });

    const getSort = (sortBy, orderBy) => ({
      sortBy,
      orderBy,
    });

    return { getSort, supplierId };
  },
};
</script>
