<template>
  <UncertainBillingTable />
</template>

<script>
import { UncertainBillingTable } from '../components/operationManagement';

export default {
  components: {
    UncertainBillingTable,
  },
};
</script>
