import { ref, computed } from 'vue';
import { Hub, Auth } from 'aws-amplify';
const ADMIN_ROLE = 'admin';

const token = ref(undefined);
const tokenPayload = ref(undefined);
const isUserAnAdmin = computed(() => {
  const membershipsString = tokenPayload.value?.memberships;
  if (!membershipsString) return false;
  const memberships = JSON.parse(membershipsString);
  const isFirstMembershipAdmin = memberships[0]?.role === ADMIN_ROLE;
  return isFirstMembershipAdmin;
});

const listener = async (data) => {
  switch (data.payload.event) {
    case 'configured':
    case 'signIn':
      try {
        const session = await Auth.currentSession();
        const sessionIdToken = session.getIdToken();
        token.value = sessionIdToken.getJwtToken();
        tokenPayload.value = sessionIdToken.payload;
      } catch (err) {
        if (err !== 'No current user') throw err;
      }
      break;
    case 'tokenRefresh': {
      const session = await Auth.currentSession();
      const sessionIdToken = session.getIdToken();
      token.value = sessionIdToken.getJwtToken();
      tokenPayload.value = sessionIdToken.payload;
      break;
    }
  }
};

Hub.listen('auth', listener);

export const useAuthToken = () => {
  return {
    token,
    tokenPayload,
    isUserAnAdmin,
  };
};
