import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import { useTenancy } from './tenancy';

export const USER_PERMISSIONS_QUERY = gql`
  query userPermissionGet {
    userPermissionGet {
      nodes {
        permissions
      }
      totalCount
    }
  }
`;

const EMPTY_PERMISSIONS = {};
export const useUserPermissions = () => {
  const { currentTenant } = useTenancy();
  const { result, loading: permissionsLoading } = useQuery(
    USER_PERMISSIONS_QUERY,
    () => ({}),
    () => ({ fetchPolicy: 'cache-first' })
  );
  const userPermissionsString = useResult(result);
  const userPermissions = computed(() => {
    if (permissionsLoading.value) return EMPTY_PERMISSIONS;
    const parsedPermissions = JSON.parse(userPermissionsString.value.nodes[0].permissions);
    return parsedPermissions;
  });

  const isUserPermittedForActiveTenant = (permissionKey) =>
    computed(() => {
      const businessId = currentTenant.value.id;
      if (businessId) {
        return (userPermissions.value[permissionKey] || []).includes(businessId);
      }
      return Boolean(userPermissions.value[permissionKey]);
    });

  const isUserPermittedForActiveTenantNonReactive = (permissionKey) => {
    const businessId = currentTenant.value.id;
    if (businessId) {
      return (userPermissions.value[permissionKey] || []).includes(businessId);
    }
    return Boolean(userPermissions.value[permissionKey]);
  };

  const isUserPermittedForSpecificTenantNonReactive = (tenantId, permissionKey) => {
    if (tenantId) {
      return (userPermissions.value[permissionKey] || []).includes(tenantId);
    }
    return Boolean(userPermissions.value[permissionKey]);
  };

  return {
    permissionsLoading,
    userPermissions,
    isUserPermittedForActiveTenant,
    isUserPermittedForActiveTenantNonReactive,
    isUserPermittedForSpecificTenantNonReactive,
  };
};
