<template>
  <BalanceAlignmentTasksTable />
</template>

<script>
import { BalanceAlignmentTasksTable } from '../components/operationManagement';

export default {
  components: { BalanceAlignmentTasksTable },
};
</script>
