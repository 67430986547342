<template>
  <SupplierCreationTable />
</template>

<script>
import { SupplierCreationTable } from '../components/operationManagement';

export default {
  components: {
    SupplierCreationTable,
  },
};
</script>
